import { React, useState } from "react";
import { HeaderLink } from "../components/HeaderLink";
import { Logo } from "../components/Logo";
import "./Header.css";

export function Header(props) {
  const links = ["Home", "Projects", "Contact"];

  return (
    <div className="header">
      <Logo />
      <HeaderLink links={links} />
    </div>
  );
}
