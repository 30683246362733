import { React, useState, useEffect } from "react";
import { Routes, Route } from "react-router-dom";

import "./Main.css";
import { Home } from "./Home";
import { Projects } from "./Projects";
import { Contacts } from "./Contacts";

export function Main(props) {
  const [scrollTop, setScrollTop] = useState(0);
  const [animateFirstSection, setAnimateFirstSection] = useState(false);

  useEffect(() => {
    const onScroll = () => {
      // It's bugged here because the Y offset never gets to 0
      let currentPage = Math.floor(window.pageYOffset / window.innerHeight);
      if (currentPage !== scrollTop) {
        setScrollTop(currentPage);
      }
    };
    // clean up code
    window.removeEventListener("scroll", onScroll);
    window.addEventListener("scroll", onScroll, { passive: true });
    return () => window.removeEventListener("scroll", onScroll);
  });

  return (
    <main>
      <Routes>
        <Route path="/" element={<Home />} />

        <Route path="/Projects" element={<Projects />} />

        <Route path="/Contact" element={<Contacts />} />
      </Routes>
    </main>
  );
}
