import React from "react";
import { NavLink } from "react-router-dom";

export function HeaderLink(props) {
  return (
    <ul>
      {props.links.map((link, index) => {
        return (
          <li key={index}>
            <NavLink
              className="navLink"
              to={link !== "Home" ? `/${link}` : "/"}
            >
              {link}
            </NavLink>
          </li>
        );
      })}
    </ul>
  );
}
