import React from "react";
import "./InfoSquare.css";
export function InfoSquare(props) {

  const subtitle = props.type !== "url" ? props.subtitle.split('/n') : [];
  return (
    <div className={`info-card ${props.className}`} >
      <div
        className={
          props.animate ? `info-square square-animation` : "info-square"
        }
        onClick={() => {
          props.type === "url" && window.open(props.subtitle);
        }}
        style={props.type === "url" ? { cursor: "pointer" } : {}}
      >
        <h2 className="info-card title">{props.title}</h2>
        {props.type !== "url" && <div className="info-card subtitle">
          {
            subtitle.map((line) => {
              return <h3>{line}</h3>
            })
          }
        </div>}

      </div>
    </div>
  );
}
