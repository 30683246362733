import React from "react";
import { InfoSquare } from "../components/InfoSquare";
import "./Contacts.css";

export function Contacts(props) {
  const info = {
    // Email: "mailto:tiago.miotto@outlook.pt",
    LinkedIn: "https://linkedin.com/in/tiago-miotto",
    GitHub: "https://github.com/tiagomiotto",
  };
  return (
    <div className="contactInfo">
      {Object.keys(info).map((key) => {
        return (
          <InfoSquare title={key} subtitle={info[key]} type="url" key={key} />
        );
      })}
    </div>
  );
}
