import React, { useState } from "react";
import { Bio } from "../components/Bio";
import { InfoSquare } from "../components/InfoSquare";
import "./Home.css";

export function Home(props) {
  const [scrollTop, setScrollTop] = useState(0);
  const [animateFirstSection, setAnimateFirstSection] = useState(false);

  const handleScroll = (event) => {
    setScrollTop(event.currentTarget.scrollTop);

    if (scrollTop > window.innerHeight) {
      setAnimateFirstSection(true);
    }
  };

  return (
    <div onScroll={handleScroll}>
      <section>
        <Bio />
      </section>
      {/* 
      <section className="info-section">
        <InfoSquare
          title="I'm Tiago"
          subtitle="A Computer Engineer from Lisbon"
          animate={animateFirstSection}
        />
      </section> */}
      {/* <section className="info-section">Hi</section> */}
    </div>
  );
}
