import React from "react";
import photo from "../assets/me.jpg";
import { InfoSquare } from "./InfoSquare";

export function Bio(props) {
  return (
    <div className="bio">
      <div className="bio-photo-card">
        <img src={photo} className="photo-me" alt="me" />
        <InfoSquare className="photo-back" title={"I'm"} subtitle={"...a Computer Engineer from Brazil, currently living in Portugal./n...passionate about technology and excited by building new things./n...currently working at Microsoft as a Cloud Solution Architect for Power Platform. "} type="text" />
      </div>

      <div className="bio-text">
        <div className="typewriter-container line1">
          <h1 className="typewriter line1">Hi there, Tiago here.</h1>
        </div>
        <br></br>
        <div className="typewriter-container line2">
          <h2 className="typewriter line2">Welcome to my website.</h2>
        </div>
        <br></br>
        <div className="typewriter-container line3">
          <h2 className="typewriter line3">Feel free to look around!</h2>
        </div>
      </div>
    </div>
  );
}
