import React from "react";
import { InfoSquare } from "../components/InfoSquare";
export function Projects(props) {
  const info = {
    // Email: "mailto:tiago.miotto@outlook.pt",
    ReddiTidder: "https://tmr-reddit-tidder.netlify.app/",
  };
  return (
    <div className="contactInfo">
      {Object.keys(info).map((key) => {
        return (
          <InfoSquare title={key} subtitle={info[key]} type="url" key={key} />
        );
      })}
    </div>
  );
}
