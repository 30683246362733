import React from "react";
import logo from "../TM.png";

export function Logo(props) {
  return (
    <div>
      <img src={logo} className="App-logo" alt="logo" />
    </div>
  );
}
