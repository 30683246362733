import logo from "./TM.png";
import "./App.css";
import { Header } from "./containers/Header";
import { Main } from "./containers/Main";
import { Routes, Route } from "react-router-dom";
import React, { useState, useEffect } from "react";

function App() {
  return (
    <div className="App">
      <header className="App-header">
        <Header />
      </header>
      <Main />
    </div>
  );
}

export default App;
